.badge {
    background-color: orange;
    border-color: lightgrey;
    border-width: 1px;
    transform: rotate(-15deg);

    /* Default value if no size was set */
    width: 20px;
    height: 20px;
    border-radius: 0.43 * 20px ;
}

.badge-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: rotate(+15deg);
}