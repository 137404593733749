@charset "UTF-8";
.grid-gutter-y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.grid-gutter-x {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media (max-width: 768px) {
  .no-padding-mobile {
    padding: 0px !important;
  }
}
@font-face {
  font-family: "tisaUeberschrift-Bold";
  src: url(../../assets/fonts/tegutTisaUeberschrift-Bold.ttf);
}
@font-face {
  font-family: "tisaUeberschrift-BoldItali";
  src: url(../../assets/fonts/tegutTisaUeberschrift-BoldItali.ttf);
}
@font-face {
  font-family: "tisaUeberschrift-Italic";
  src: url(../../assets/fonts/tegutTisaUeberschrift-Italic.ttf);
}
@font-face {
  font-family: "tisaUeberschrift";
  src: url(../../assets/fonts/tegutTisaUeberschrift.ttf);
}
@font-face {
  font-family: "unitText-Bold";
  src: url(../../assets/fonts/tegutUnitText-Bold.ttf);
}
@font-face {
  font-family: "unitText-Medium";
  src: url(../../assets/fonts/tegutUnitText-Medium.ttf);
}
@font-face {
  font-family: "unitText-Regular";
  src: url(../../assets/fonts/tegutUnitText-Regular.ttf);
}
.font-tisaUerschrift-Bold {
  font-family: "tisaUeberschrift-Bold";
}

.font-tisaUeberschrift-BoldItali {
  font-family: "tisaUeberschrift-BoldItali";
}

.font-tisaUeberschrift-Italic {
  font-family: "tisaUeberschrift-Italic";
}

.font-tisaUeberschrift {
  font-family: "tisaUeberschrift";
}

.font-unitText-Bold {
  font-family: "unitText-Bold" !important;
}

.font-unitText-Medium {
  font-family: "unitText-Medium";
}

.font-unitText-Regular {
  font-family: "unitText-Regular";
}

.font-orange {
  color: #ff5200 !important;
}

.font-white {
  color: #ffffff !important;
}

.font-dimGray {
  color: #707070 !important;
}

.font-plumPie {
  color: #741635 !important;
}

.font-silver {
  color: #bebebe !important;
}

.font-seaWeedGreen {
  color: #65bb25 !important;
}

.font-red {
  color: #e50000 !important;
}

.font-heading {
  color: #ffffff;
  font-size: 1.25rem;
  font-family: "unitText-Bold";
}

.font-subheading {
  margin-bottom: 5px;
  font-family: "unitText-Bold";
  font-size: 1.563rem;
  color: #4c4c4c;
}

.font-subheading-regular {
  color: #4c4c4c;
  font-size: 1.25rem !important;
  font-family: "unitText-Regular";
}

.font-description {
  font-family: "unitText-Regular";
  font-size: 0.938rem;
  color: #4c4c4c;
}

.font-footer {
  font-family: "unitText-Bold";
  font-size: 1.25rem;
  color: #4c4c4c;
}

.font-table-heading {
  font-family: "unitText-Regular";
  font-size: 0.81rem !important;
  color: #979797 !important;
}

.font-table-body {
  font-family: "unitText-Regular";
  font-size: 0.85rem !important;
  color: #4c4c4c !important;
}

.font-date-selector {
  font-size: 0.923rem !important;
  color: #4c4c4c !important;
}

.font-news-title {
  font-family: "unitText-Bold" !important;
  font-size: 1.875rem;
  color: #4c4c4c;
}

.font-FAQs-category {
  font-family: "unitText-Bold";
  font-size: 1.25rem;
  color: #4c4c4c;
}

.font-FAQs-questions {
  font-family: "unitText-Bold" !important;
  font-size: 0.923rem;
  color: #4c4c4c;
}

.font-FAQs-answers {
  font-family: "unitText-regular" !important;
  font-size: 0.82rem !important;
  color: #4c4c4c;
}

.font-article-categories {
  font-family: "unitText-Bold";
  font-size: 0.938rem;
  color: #979797 !important;
}

@media (min-width: 768px) {
  .font-heading {
    color: #ff5200;
    font-size: 2.375rem;
    font-family: "unitText-Medium";
  }
  .font-subheading {
    font-family: "unitText-Bold";
    font-size: 1.563rem;
    color: #4c4c4c;
  }
  .font-subheading-regular {
    font-size: 1.563rem !important;
  }
  .font-table-heading {
    font-size: 0.938rem !important;
  }
  .font-table-body {
    font-size: 0.938rem !important;
  }
  .font-FAQs-category {
    font-size: 1.563rem;
  }
  .font-FAQs-questions {
    font-size: 1.0539rem;
  }
  .font-FAQs-answers {
    font-size: 1rem !important;
  }
}
.emptyProductTile {
  margin: 15px;
  padding: 10px;
  width: 200px;
  height: 250px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.emptyProductTile-badge {
  width: 110px;
  height: 110px;
  border-radius: 47.3px !important;
  border: 1px solid #707070;
}

.productTile {
  margin: 15px;
  padding: 10px;
  width: 200px;
  min-height: 275px;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.badge-select {
  width: 40px;
  height: 40px;
  border-radius: 17.2px !important;
}

.badge-select-text {
  font-size: 30px !important;
}

.badge-multiplier {
  color: #ffffff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 21.5px !important;
}

.badge-advantageAmount-points {
  font-size: 20px;
}

.badge-advantageAmount-text {
  font-size: 10px;
}

.product-image {
  object-fit: contain;
  width: 100%;
  height: 140px;
}

.promotion-title {
  font-family: "unitText-Medium";
  font-size: 0.813rem;
  width: 85% !important;
}

@media (max-width: 768px) {
  .emptyProductTile {
    margin: 10px;
    padding: 8px;
    width: 120px;
    height: 160px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .emptyProductTile-badge {
    width: 80px;
    height: 80px;
    border-radius: 34.4px !important;
  }
  .productTile {
    margin: 10px;
    padding: 8px;
    width: 120px;
    min-height: 160px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .badge-select {
    width: 30px;
    height: 30px;
    border-radius: 12.9px !important;
  }
  .badge-select-text {
    font-size: 25px !important;
  }
  .badge-multiplier {
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border-radius: 17.2px !important;
  }
  .badge-advantageAmount-points {
    font-size: 15px !important;
  }
  .badge-advantageAmount-text {
    font-size: 8px !important;
  }
  .product-image {
    object-fit: contain;
    width: 100%;
    height: 80px;
  }
  .promotion-title {
    font-family: "unitText-Regular";
    font-size: 0.688rem;
  }
}
.tile-inactive {
  opacity: 0.5;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.parent-overlapping {
  position: relative;
  top: 0;
  left: 0;
}

.productTile-selected {
  border: 3px solid #65bb25 !important;
}

.productTile-deselected {
  border: 3px solid #ffffff !important;
}

.btn-badge-select {
  display: flex;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 0px !important;
  font-size: 20px;
  line-height: normal;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-badge-select:hover:not([disabled]) {
  color: #fbba00 !important;
  background-color: transparent;
}

.emptyProductTile-badge-dashboard {
  width: 80px;
  height: 80px;
  border-radius: 34.4px !important;
  border: 1px solid #707070;
}

.dashboardTile-promotions {
  margin: 8px;
  padding: 10px;
  width: 130px;
  height: 125px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.badge-multiplier-dashboard-promotions {
  color: #ffffff;
  position: absolute;
  top: auto;
  left: auto;
  right: 10px;
  bottom: 10px;
  width: 45px;
  height: 45px;
  border-radius: 19.35px !important;
}

.badge-select-button-dashboard {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 10.75px !important;
}

.badge-select-icon-dashboard-promotions {
  font-size: 125% !important;
}

.badge-advantageAmount-points-dashboard-promotions {
  font-size: 18px;
}

.badge-advantageAmount-text-dashboard-promotions {
  font-size: 10px;
}

@media (max-width: 768px) {
  .emptyProductTile-badge-dashboard {
    width: 50px;
    height: 50px;
    border-radius: 21.5px !important;
  }
  .dashboardTile-promotions {
    margin: 5px;
    padding: 10px;
    width: 80px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .badge-multiplier-dashboard-promotions {
    color: #ffffff;
    position: absolute;
    top: auto;
    left: auto;
    right: 5px;
    bottom: 5px;
    width: 30px;
    height: 30px;
    border-radius: 12.9px !important;
  }
  .badge-select-button-dashboard {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    width: 15px;
    height: 15px;
    border-radius: 6.45px !important;
  }
  .badge-select-icon-dashboard-promotions {
    font-size: 100% !important;
  }
  .badge-advantageAmount-points-dashboard-promotions {
    font-size: 12px;
  }
  .badge-advantageAmount-text-dashboard-promotions {
    font-size: 6px;
  }
}
.dashboardTile-weeklyHits {
  margin: 0px;
  padding: 10px;
  width: 160px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.badge-multiplier-dashboard-weeklyHits {
  color: #ffffff;
  position: absolute;
  top: auto;
  left: auto;
  right: 15px;
  bottom: 15px;
  width: 60px;
  height: 60px;
  border-radius: 25.8px !important;
}

.badge-advantageAmount-points-dashboard-weeklyHits {
  font-size: 25px;
}

.badge-advantageAmount-text-dashboard-weeklyHits {
  font-size: 12px;
}

@media (max-width: 1440px) {
  .dashboardTile-weeklyHits {
    width: 140px;
    height: 170px;
  }
  .badge-multiplier-dashboard-weeklyHits {
    right: 3px;
    bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .dashboardTile-weeklyHits {
    width: 110px;
    height: 140px;
  }
  .badge-multiplier-dashboard-weeklyHits {
    right: 3px;
    bottom: 10px;
    width: 45px;
    height: 45px;
    border-radius: 19.35px !important;
  }
  .badge-advantageAmount-points-dashboard-weeklyHits {
    font-size: 15px;
  }
  .badge-advantageAmount-text-dashboard-weeklyHits {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .dashboardTile-weeklyHits {
    padding: 8px;
    width: 80px;
    height: 85px;
  }
  .badge-multiplier-dashboard-weeklyHits {
    right: 4px;
    bottom: 7px;
    width: 30px;
    height: 30px;
    border-radius: 12.9px !important;
  }
  .badge-advantageAmount-points-dashboard-weeklyHits {
    font-size: 11px;
  }
  .badge-advantageAmount-text-dashboard-weeklyHits {
    font-size: 7px;
  }
}
.emptyMultipierTileDashboard {
  margin: 8px;
  padding: 10px;
  width: 130px;
  max-height: 140px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.badge-multiplier-dashboard {
  color: #ffffff;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  border-radius: 21.5px !important;
}

.badge-advantageAmount-points-dashboard-fav {
  font-size: 20px;
}

.badge-advantageAmount-text-dashboard-fav {
  font-size: 10px;
}

.badge-check-dashboard-fav {
  width: 25px;
  height: 25px;
  border-radius: 10.75px !important;
}

.check-icon-fav {
  font-size: 25px !important;
  color: #ffffff !important;
}

.img-product-fav {
  width: 100%;
  height: 95px;
}

.img-empty-badge-fav {
  width: 80px;
  height: 80px;
  border-radius: 34.4px !important;
  border: 1px solid #707070;
}

.flip-card {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transition: transform 0.5s linear 0.1s;
  -webkit-transition: -webkit-transform 0.5s linear 0.1s;
  position: relative;
}
.flip-card.showBack {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180.000222999deg);
}
.flip-card .card {
  border: none !important;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.flip-card .card.card-front {
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  height: 100%;
  background-color: #ffffff;
}
.flip-card .card.card-front .info-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.flip-card .card.card-back {
  transform: rotateY(180deg);
  background-color: #ffffff;
  -webkit-transform: rotateY(180deg);
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  border-radius: 20px !important;
}
.flip-card .card.card-back .promotion-title {
  margin-top: 10px !important;
  overflow-y: auto !important;
}

@media (max-width: 768px) {
  .emptyMultipierTileDashboard {
    margin: 10px;
    padding: 5px;
    width: 80px;
    max-height: 95px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
  }
  .badge-multiplier-dashboard {
    color: #ffffff;
    position: absolute;
    top: auto;
    left: auto;
    right: 0px;
    bottom: 0px;
    width: 30px;
    height: 30px;
    border-radius: 12.9px !important;
  }
  .badge-advantageAmount-points-dashboard-fav {
    font-size: 12px;
  }
  .badge-advantageAmount-text-dashboard-fav {
    font-size: 7px;
  }
  .badge-check-dashboard-fav {
    width: 15px;
    height: 15px;
    border-radius: 6.45px !important;
  }
  .check-icon-fav {
    font-size: 15px !important;
    color: #ffffff !important;
  }
  .img-product-fav {
    width: 100%;
    height: 70px;
  }
  .img-empty-badge-fav {
    width: 60px;
    height: 60px;
    border-radius: 25.8px !important;
  }
}
.multiplierDetailsTile {
  margin: 15px;
  padding: 10px;
  width: 200px;
  min-height: 275px;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.emptyMultiplierTileDetails {
  margin: 15px;
  padding: 10px;
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.badge-check-detail-fav {
  width: 40px;
  height: 40px;
  border-radius: 17.2px !important;
}

.badge-multiplier-details {
  color: #ffffff;
  position: absolute;
  right: 0px;
  bottom: 5px;
  width: 60px;
  height: 60px;
  border-radius: 25.8px !important;
}

.badge-advantageAmount-points-detail-fav {
  font-size: 25px;
}

.badge-advantageAmount-text-detail-fav {
  font-size: 12px;
}

.img-product-detail-fav {
  width: 100%;
  height: 150px;
}

.check-icon-detail-fav {
  font-size: 25px !important;
  color: #ffffff !important;
  -webkit-margin-after: 5px;
}

.img-empty-badge-detail-fav {
  width: 110px;
  height: 110px;
  border-radius: 47.3px !important;
  border: 1px solid #707070;
}

@media (max-width: 768px) {
  .multiplierDetailsTile {
    margin: 10px;
    padding: 8px;
    width: 120px;
    min-height: 170px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .emptyMultiplierTileDetails {
    margin: 10px;
    padding: 8px;
    width: 120px;
    height: 170px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .badge-check-detail-fav {
    width: 25px;
    height: 25px;
    border-radius: 10.75px !important;
  }
  .badge-multiplier-details {
    color: #ffffff;
    position: absolute;
    right: 0px;
    bottom: 5px;
    width: 35px;
    height: 35px;
    border-radius: 15.05px !important;
  }
  .badge-advantageAmount-points-detail-fav {
    font-size: 13px;
  }
  .badge-advantageAmount-text-detail-fav {
    font-size: 6px;
  }
  .img-product-detail-fav {
    width: 100%;
    height: 100px;
  }
  .check-icon-detail-fav {
    font-size: 25px !important;
    color: #ffffff !important;
    -webkit-margin-after: 2px;
  }
  .img-empty-badge-detail-fav {
    width: 80px;
    height: 80px;
    border-radius: 34.4px !important;
  }
}
.btn {
  padding: 0.2rem 0.75rem !important;
  border-radius: 8px !important;
}
.btn:hover {
  transition-duration: 250ms !important;
}

.btn-outline-white {
  color: #ffffff !important;
  border-color: #ffffff !important;
  white-space: nowrap;
}
.btn-outline-white:hover {
  color: #000000 !important;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white .btn-outline-white:focus,
.btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem #000000 !important;
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #707070;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #000000 !important;
}

.btn-outline-orange {
  color: #ff5200 !important;
  border-color: #ff5200 !important;
  border-width: 2px !important;
}
.btn-outline-orange:hover {
  background-color: rgba(255, 82, 0, 0.1490196078);
}

.btn-outline-orange:focus,
.btn-outline-orange.focus {
  box-shadow: 0 0 0 0.1rem #ff5200 !important;
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #707070;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #ff5200;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem #ff5200 !important;
  color: #ff5200 !important;
}

.btn-orange {
  color: #ffffff !important;
  background-color: #ff5200 !important;
  border-color: #ff5200;
}
.btn-orange:hover {
  color: #ffffff !important;
  background-color: #d14300 !important;
  border-color: #d14300 !important;
}

.btn-orange:focus,
.btn-orange.focus {
  color: #ffffff !important;
  background-color: #ff5200 !important;
  border-color: #ff5200 !important;
  box-shadow: 0 0 0 0.1rem #222222 !important;
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #bebebe !important;
  background-color: transparent !important;
  border-color: #bebebe !important;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #ffffff !important;
  background-color: #ff5200 !important;
  border-color: #222222 !important;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem #222222 !important;
}

.btn-white {
  color: #ff5200 !important;
  background-color: #ffffff !important;
  border-color: #ffffff;
}
.btn-white:hover {
  color: #ffffff !important;
  background-color: #ff5200 !important;
  border-color: #ffffff !important;
}

.btn-white:focus,
.btn-white.focus {
  color: #ff5200 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.1rem #222222 !important;
}

.btn-white.disabled,
.btn-white:disabled {
  color: #bebebe !important;
  background-color: transparent !important;
  border-color: #bebebe !important;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #ff5200 !important;
  background-color: #ffffff !important;
  border-color: #222222 !important;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem #222222 !important;
}

.btn-article-search {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

.btn-navigationBar {
  font-size: 1.25rem !important;
}

.btn-footer {
  font-size: 0.938rem !important;
}

@media (max-width: 1024px) {
  .btn-footer {
    font-size: 0.813rem !important;
  }
}
.btn-select {
  font-family: "unitText-Bold";
  min-width: 250px;
  min-height: 40px;
  flex-wrap: nowrap;
}

.btn-orange-text {
  border: none !important;
  color: #ff5200 !important;
  background-color: transparent !important;
}
.btn-orange-text:hover {
  color: #e29d73 !important;
  border: none !important;
}
.btn-orange-text:active,
.btn-orange-text :focus {
  color: #ff5200 !important;
  border: none !important;
}

.circle {
  width: 20px;
  height: 20px;
  line-height: 10px;
  border-radius: 100%;
  border-style: solid;
  text-align: center;
  background: transparent;
  padding: 0;
}

.center-on-container {
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  z-index: 10;
}

@media (max-width: 768px) {
  .center-on-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }
}
.centered-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-position {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-tile-information {
  margin: 0px;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 40px;
  padding-right: 40px;
  width: 400px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  overflow-y: auto;
}

@media (max-width: 768px) {
  .modal-position {
    justify-content: flex-start;
    margin-top: 5px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
  .modal-tile-information {
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 400px;
    width: 85%;
    height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1 !important;
  padding: 1rem 0rem;
}

.modal-body-text {
  font-size: 0.938rem;
}

.modal-bg-disabled {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(34, 34, 34, 0.3333333333);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.analyze-opt-in-modal {
  margin: 0px;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 40px;
  padding-right: 40px;
  width: 450px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .analyze-opt-in-modal {
    margin: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    width: 350px;
    height: auto;
    max-height: 70vh;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
.tebonus-benefits-link {
  text-decoration: underline;
}
.tebonus-benefits-link:hover {
  cursor: pointer;
}

.benefit-modal-overlay {
  width: calc(100% + 20px);
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  margin: 0px;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .benefit-modal-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    margin: 0px;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
.benefits-modal-content {
  z-index: 999;
  width: 350px;
  margin: 20px;
  background-color: #ffffff;
  border-color: #f2f2f2;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.modal-content-multiplier {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border-radius: 1.25rem;
  outline: 0;
}

@media (max-width: 768px) {
  .modal-content-multiplier {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
    width: 100%;
    height: auto;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
.multiplier-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.multiplier-container {
  justify-content: space-between;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.multiplier-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  padding-top: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.modal-detail-badge {
  top: 40px;
  left: 40px;
}

.multipler-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.selected-multiplier {
  height: 70px;
  object-fit: contain;
}

.unselected-multiplier {
  width: 70px;
  height: 70px;
}

.modal-icon-badge {
  width: 60px;
  height: 60px;
  border-radius: 25.8px !important;
}

.modal-close-icon {
  color: #e50000;
}

.multiplier-modal-image-container {
  width: 7.5rem;
  max-width: 7.5rem;
}

@media (max-width: 768px) {
  .modal-icon-badge {
    width: 50px;
    height: 50px;
    border-radius: 21.5px !important;
  }
  .modal-close-icon {
    size: 40px;
  }
  .modal-check-icon {
    height: 20px;
    width: 20px;
  }
  .selected-multiplier {
    height: 50px;
  }
  .unselected-multiplier {
    height: 50px;
    width: 50px;
  }
  .modal-detail-badge {
    top: 30px;
    left: 30px;
  }
  .multiplier-modal-image-container {
    width: 5.625rem;
    max-width: 5.625rem;
  }
}
.dashboard-icon {
  width: 70px;
  height: 70px;
  border-radius: 30.1px !important;
}

.dashboard-icon-small {
  width: 41px;
  height: 41px;
  border-radius: 17.63px !important;
}

.dashboard-tile-title {
  color: #ff5200;
  font-size: 1.75rem;
  font-weight: bold;
}

.dashboard-tile-subheader {
  font-size: 0.938rem;
  font-family: "unitText-Regular";
  color: #4c4c4c;
}

.dashboard-tile-footer {
  font-size: 0.938rem;
  font-family: "unitText-Regular";
  color: #4c4c4c;
}

.weeklyHits-TitleContainer {
  width: 200px;
}

.weeklyHits-Carousel {
  width: calc(100% - 200px);
}

.weeklyHitsLink {
  color: #ff5200;
  text-decoration: underline;
  font-size: 1.25rem;
}
.weeklyHitsLink:hover {
  color: #d14300;
}
.weeklyHitsLink:active {
  color: #d14300;
  text-decoration: none;
}

.weeklyHitsFromToText {
  font-size: 1.25rem;
  color: #ff5200;
}

@media (max-width: 768px) {
  .dashboard-icon {
    width: 36px;
    height: 36px;
    border-radius: 15.48px !important;
  }
  .dashboard-icon-small {
    width: 36px;
    height: 36px;
    border-radius: 15.48px !important;
  }
  .dashboard-tile-title {
    color: #ff5200;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .dashboard-tile-subheader {
    font-size: 0.813rem;
    font-family: "unitText-Regular";
    color: #4c4c4c;
  }
  .dashboard-tile-footer {
    font-size: 0.813rem;
    font-family: "unitText-Regular";
    color: #4c4c4c;
  }
  .weeklyHits-TitleContainer {
    width: 100%;
    text-align: justify;
    text-align: center;
    align-items: center;
  }
  .weeklyHits-Carousel {
    width: 100%;
  }
}
.greetingTitle {
  font-size: 2.5rem;
  line-height: 2.688rem;
  color: #741635;
  font-family: "unitText-Bold";
}

.greetingSubTitle {
  font-size: 1.5rem;
  line-height: 1.625rem;
  color: #741635;
  font-family: "unitText-Bold";
}

@media (max-width: 768px) {
  .greetingTitle {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #741635;
    font-family: "unitText-Bold";
  }
  .greetingSubTitle {
    font-size: 0.938rem;
    line-height: 1.25rem;
    color: #741635;
    font-family: "unitText-Regular";
  }
}
.pointsLink {
  text-decoration: underline;
  color: #741635;
}
.pointsLink:hover {
  text-decoration: none;
  color: #741635;
}
.pointsLink:active {
  text-decoration: none;
}

.greetings-bg-logo {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  width: auto;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.truncate-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.dashboardNewsTitle {
  font-size: 2.25rem;
  line-height: 2.7rem;
  color: #4c4c4c;
  word-break: break-word;
}

.dashboard-news-carousel:hover,
.dashboard-news-carousel :focus {
  cursor: pointer;
}

.custom-container {
  float: left;
  width: calc(100% - 2 * 30px);
  background: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}

.arrows {
  width: 30px;
  float: left;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: #ff5200;
}
.arrows:disabled {
  color: #f2f2f2;
}

.arrows-hidden {
  visibility: hidden;
}

.carousel-items {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.arrowSize {
  font-size: 6.25rem !important;
}

@media (max-width: 768px) {
  .arrowSize {
    font-size: 3.125rem !important;
  }
}
.custom-button-group {
  position: absolute;
}

#legalDocuments h1 {
  font-size: 2rem;
  font-weight: bold;
}

#legalDocuments h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  #legalDocuments h1 {
    font-size: 1.563rem;
  }
  #legalDocuments h2 {
    font-size: 1.2rem;
  }
}
#legalDocuments a {
  color: #ff5200;
}

.search-bar-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar-wrapper {
  background-color: #f2f2f2;
  border-color: #707070;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  width: 90%;
  border: none;
  background-color: #f2f2f2;
}
.search-input:focus {
  outline: none;
}

.search-bar-icon-visible {
  color: #707070;
  margin: 5px;
}

.close-icon {
  cursor: pointer;
}

.search-bar-icon-invisible {
  color: #f2f2f2;
}

.barcode-img {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 768px) {
  .overflow-y-md {
    overflow-y: auto;
  }
  .scrollScoreContainer-md {
    min-height: 450px;
    max-height: calc(100vh - 450px);
  }
  .scrollContainer-md {
    min-height: 600px !important;
    max-height: calc(100vh - 320px) !important;
  }
}
/* Custom Table scrollbar for Chromium based Browsers */
.table-scroll-bar::-webkit-scrollbar {
  width: 7px;
}

.table-scroll-bar::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 8px;
}

.table-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 8px;
}
.table-scroll-bar::-webkit-scrollbar-thumb:hover {
  background-color: #ff5200;
}

/* Custom Table scrollbar for Firefox */
.table-scroll-bar {
  scrollbar-color: #bebebe #f2f2f2;
  scrollbar-width: thin;
}

.nav-link-footer {
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.nav-link-footer:hover {
  color: #ff5200 !important;
}
.nav-link-footer.active {
  color: #ff5200 !important;
}

@media (max-width: 1024px) {
  .nav-link-footer {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.footer {
  margin-top: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #741635;
  font-size: 0.938rem !important;
  font-family: "unitText-Bold";
}

@media (max-width: 1024px) {
  .footer {
    font-size: 0.813rem !important;
    font-family: "unitText-Regular";
  }
}
.width-50-lg-auto {
  width: 50%;
}

@media (min-width: 1024px) {
  .width-50-lg-auto {
    width: auto !important;
  }
}
.width-100-lg-auto {
  width: 100%;
}

@media (min-width: 1024px) {
  .width-100-lg-auto {
    width: auto !important;
  }
}
.navigationBar {
  font-size: 1.438rem !important;
  font-family: "unitText-Regular";
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.fixed-top {
  max-height: 100vh;
  overflow-y: auto !important;
}

.nav-sub {
  margin-bottom: 12px !important;
}

.nav-link {
  display: inline-block !important;
  padding: 0rem 0px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.nav-link-submenu {
  display: inline-block !important;
  padding: 0rem 0px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.nav-link-submenu:hover {
  color: #ff5200 !important;
  text-decoration: none;
}

.nav-link-submenu.active {
  color: #ff5200 !important;
  font-weight: bold;
}

.nav-link-toggle {
  background: transparent;
  border: 0;
  transition: transform 0.25s;
}

.nav-link-toggle[aria-expanded=true] {
  transform: rotate(180deg);
}

.nav-border {
  border: 1px solid #f2f2f2;
  border-width: 1px 0 0px 0px;
}

.nav-link-title {
  color: #741635;
  font-weight: bold;
  font-size: 1.438rem;
  padding: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 0px !important;
  font-family: "unitText-Bold";
  hyphens: none !important;
}

.nav-link-sub-title {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #222222;
  font-size: 0.938rem !important;
  font-family: "unitText-Medium";
}

.nav-link.active {
  color: #ff5200 !important;
  font-weight: bold;
}

.nav-link:hover {
  color: #ff5200 !important;
  transition-duration: 250ms !important;
}

.sticky-top {
  z-index: 9 !important;
}

#privacyPolicy h1 {
  font-size: 2rem;
  font-weight: bold;
}

#privacyPolicy h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

#privacyPolicy p {
  font-size: 0.95rem !important;
}

@media (max-width: 768px) {
  #privacyPolicy h1 {
    font-size: 1.563rem;
  }
  #privacyPolicy h2 {
    font-size: 1.2rem;
  }
}
#privacyPolicy a {
  color: #ff5200;
}

#privacyPolicy a:hover {
  color: #d14300;
}

.grid-gutter-y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.grid-gutter-x {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media (max-width: 768px) {
  .no-padding-mobile {
    padding: 0px !important;
  }
}
.thead {
  border-top: none !important;
}

/* Score Table row Animation */
.slide-row-left {
  animation: slide-row-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-row-left:nth-child(1) {
  animation-delay: 70ms;
}
.slide-row-left:nth-child(2) {
  animation-delay: 140ms;
}
.slide-row-left:nth-child(3) {
  animation-delay: 210ms;
}
.slide-row-left:nth-child(4) {
  animation-delay: 280ms;
}
.slide-row-left:nth-child(5) {
  animation-delay: 350ms;
}
.slide-row-left:nth-child(6) {
  animation-delay: 420ms;
}
.slide-row-left:nth-child(7) {
  animation-delay: 490ms;
}
.slide-row-left:nth-child(8) {
  animation-delay: 560ms;
}
.slide-row-left:nth-child(9) {
  animation-delay: 630ms;
}
.slide-row-left:nth-child(10) {
  animation-delay: 700ms;
}
.slide-row-left:nth-child(11) {
  animation-delay: 770ms;
}
.slide-row-left:nth-child(12) {
  animation-delay: 840ms;
}
.slide-row-left:nth-child(13) {
  animation-delay: 910ms;
}
.slide-row-left:nth-child(14) {
  animation-delay: 980ms;
}
.slide-row-left:nth-child(15) {
  animation-delay: 1050ms;
}
.slide-row-left:nth-child(16) {
  animation-delay: 1120ms;
}
.slide-row-left:nth-child(17) {
  animation-delay: 1190ms;
}
.slide-row-left:nth-child(18) {
  animation-delay: 1260ms;
}
.slide-row-left:nth-child(19) {
  animation-delay: 1330ms;
}
.slide-row-left:nth-child(20) {
  animation-delay: 1400ms;
}
.slide-row-left:nth-child(21) {
  animation-delay: 1470ms;
}
.slide-row-left:nth-child(22) {
  animation-delay: 1540ms;
}
.slide-row-left:nth-child(23) {
  animation-delay: 1610ms;
}
.slide-row-left:nth-child(24) {
  animation-delay: 1680ms;
}
.slide-row-left:nth-child(25) {
  animation-delay: 1750ms;
}
.slide-row-left:nth-child(26) {
  animation-delay: 1820ms;
}
.slide-row-left:nth-child(27) {
  animation-delay: 1890ms;
}
.slide-row-left:nth-child(28) {
  animation-delay: 1960ms;
}
.slide-row-left:nth-child(29) {
  animation-delay: 2030ms;
}
.slide-row-left:nth-child(30) {
  animation-delay: 2100ms;
}
.slide-row-left:nth-child(31) {
  animation-delay: 2170ms;
}
.slide-row-left:nth-child(32) {
  animation-delay: 2240ms;
}
.slide-row-left:nth-child(33) {
  animation-delay: 2310ms;
}
.slide-row-left:nth-child(34) {
  animation-delay: 2380ms;
}
.slide-row-left:nth-child(35) {
  animation-delay: 2450ms;
}
.slide-row-left:nth-child(36) {
  animation-delay: 2520ms;
}
.slide-row-left:nth-child(37) {
  animation-delay: 2590ms;
}
.slide-row-left:nth-child(38) {
  animation-delay: 2660ms;
}
.slide-row-left:nth-child(39) {
  animation-delay: 2730ms;
}
.slide-row-left:nth-child(40) {
  animation-delay: 2800ms;
}
.slide-row-left:nth-child(41) {
  animation-delay: 2870ms;
}
.slide-row-left:nth-child(42) {
  animation-delay: 2940ms;
}
.slide-row-left:nth-child(43) {
  animation-delay: 3010ms;
}
.slide-row-left:nth-child(44) {
  animation-delay: 3080ms;
}
.slide-row-left:nth-child(45) {
  animation-delay: 3150ms;
}
.slide-row-left:nth-child(46) {
  animation-delay: 3220ms;
}
.slide-row-left:nth-child(47) {
  animation-delay: 3290ms;
}
.slide-row-left:nth-child(48) {
  animation-delay: 3360ms;
}
.slide-row-left:nth-child(49) {
  animation-delay: 3430ms;
}
.slide-row-left:nth-child(50) {
  animation-delay: 3500ms;
}
.slide-row-left:nth-child(51) {
  animation-delay: 3570ms;
}
.slide-row-left:nth-child(52) {
  animation-delay: 3640ms;
}
.slide-row-left:nth-child(53) {
  animation-delay: 3710ms;
}
.slide-row-left:nth-child(54) {
  animation-delay: 3780ms;
}
.slide-row-left:nth-child(55) {
  animation-delay: 3850ms;
}
.slide-row-left:nth-child(56) {
  animation-delay: 3920ms;
}
.slide-row-left:nth-child(57) {
  animation-delay: 3990ms;
}
.slide-row-left:nth-child(58) {
  animation-delay: 4060ms;
}
.slide-row-left:nth-child(59) {
  animation-delay: 4130ms;
}
.slide-row-left:nth-child(60) {
  animation-delay: 4200ms;
}
.slide-row-left:nth-child(61) {
  animation-delay: 4270ms;
}
.slide-row-left:nth-child(62) {
  animation-delay: 4340ms;
}
.slide-row-left:nth-child(63) {
  animation-delay: 4410ms;
}
.slide-row-left:nth-child(64) {
  animation-delay: 4480ms;
}
.slide-row-left:nth-child(65) {
  animation-delay: 4550ms;
}
.slide-row-left:nth-child(66) {
  animation-delay: 4620ms;
}
.slide-row-left:nth-child(67) {
  animation-delay: 4690ms;
}
.slide-row-left:nth-child(68) {
  animation-delay: 4760ms;
}
.slide-row-left:nth-child(69) {
  animation-delay: 4830ms;
}
.slide-row-left:nth-child(70) {
  animation-delay: 4900ms;
}
.slide-row-left:nth-child(71) {
  animation-delay: 4970ms;
}
.slide-row-left:nth-child(72) {
  animation-delay: 5040ms;
}
.slide-row-left:nth-child(73) {
  animation-delay: 5110ms;
}
.slide-row-left:nth-child(74) {
  animation-delay: 5180ms;
}
.slide-row-left:nth-child(75) {
  animation-delay: 5250ms;
}
.slide-row-left:nth-child(76) {
  animation-delay: 5320ms;
}
.slide-row-left:nth-child(77) {
  animation-delay: 5390ms;
}
.slide-row-left:nth-child(78) {
  animation-delay: 5460ms;
}
.slide-row-left:nth-child(79) {
  animation-delay: 5530ms;
}
.slide-row-left:nth-child(80) {
  animation-delay: 5600ms;
}
.slide-row-left:nth-child(81) {
  animation-delay: 5670ms;
}
.slide-row-left:nth-child(82) {
  animation-delay: 5740ms;
}
.slide-row-left:nth-child(83) {
  animation-delay: 5810ms;
}
.slide-row-left:nth-child(84) {
  animation-delay: 5880ms;
}
.slide-row-left:nth-child(85) {
  animation-delay: 5950ms;
}
.slide-row-left:nth-child(86) {
  animation-delay: 6020ms;
}
.slide-row-left:nth-child(87) {
  animation-delay: 6090ms;
}
.slide-row-left:nth-child(88) {
  animation-delay: 6160ms;
}
.slide-row-left:nth-child(89) {
  animation-delay: 6230ms;
}
.slide-row-left:nth-child(90) {
  animation-delay: 6300ms;
}
.slide-row-left:nth-child(91) {
  animation-delay: 6370ms;
}
.slide-row-left:nth-child(92) {
  animation-delay: 6440ms;
}
.slide-row-left:nth-child(93) {
  animation-delay: 6510ms;
}
.slide-row-left:nth-child(94) {
  animation-delay: 6580ms;
}
.slide-row-left:nth-child(95) {
  animation-delay: 6650ms;
}
.slide-row-left:nth-child(96) {
  animation-delay: 6720ms;
}
.slide-row-left:nth-child(97) {
  animation-delay: 6790ms;
}
.slide-row-left:nth-child(98) {
  animation-delay: 6860ms;
}
.slide-row-left:nth-child(99) {
  animation-delay: 6930ms;
}
.slide-row-left:nth-child(100) {
  animation-delay: 7000ms;
}
.slide-row-left:nth-child(101) {
  animation-delay: 7070ms;
}
.slide-row-left:nth-child(102) {
  animation-delay: 7140ms;
}
.slide-row-left:nth-child(103) {
  animation-delay: 7210ms;
}
.slide-row-left:nth-child(104) {
  animation-delay: 7280ms;
}
.slide-row-left:nth-child(105) {
  animation-delay: 7350ms;
}
.slide-row-left:nth-child(106) {
  animation-delay: 7420ms;
}
.slide-row-left:nth-child(107) {
  animation-delay: 7490ms;
}
.slide-row-left:nth-child(108) {
  animation-delay: 7560ms;
}
.slide-row-left:nth-child(109) {
  animation-delay: 7630ms;
}
.slide-row-left:nth-child(110) {
  animation-delay: 7700ms;
}
.slide-row-left:nth-child(111) {
  animation-delay: 7770ms;
}
.slide-row-left:nth-child(112) {
  animation-delay: 7840ms;
}
.slide-row-left:nth-child(113) {
  animation-delay: 7910ms;
}
.slide-row-left:nth-child(114) {
  animation-delay: 7980ms;
}
.slide-row-left:nth-child(115) {
  animation-delay: 8050ms;
}
.slide-row-left:nth-child(116) {
  animation-delay: 8120ms;
}
.slide-row-left:nth-child(117) {
  animation-delay: 8190ms;
}
.slide-row-left:nth-child(118) {
  animation-delay: 8260ms;
}
.slide-row-left:nth-child(119) {
  animation-delay: 8330ms;
}
.slide-row-left:nth-child(120) {
  animation-delay: 8400ms;
}
.slide-row-left:nth-child(121) {
  animation-delay: 8470ms;
}
.slide-row-left:nth-child(122) {
  animation-delay: 8540ms;
}
.slide-row-left:nth-child(123) {
  animation-delay: 8610ms;
}
.slide-row-left:nth-child(124) {
  animation-delay: 8680ms;
}
.slide-row-left:nth-child(125) {
  animation-delay: 8750ms;
}
.slide-row-left:nth-child(126) {
  animation-delay: 8820ms;
}
.slide-row-left:nth-child(127) {
  animation-delay: 8890ms;
}
.slide-row-left:nth-child(128) {
  animation-delay: 8960ms;
}
.slide-row-left:nth-child(129) {
  animation-delay: 9030ms;
}
.slide-row-left:nth-child(130) {
  animation-delay: 9100ms;
}
.slide-row-left:nth-child(131) {
  animation-delay: 9170ms;
}
.slide-row-left:nth-child(132) {
  animation-delay: 9240ms;
}
.slide-row-left:nth-child(133) {
  animation-delay: 9310ms;
}
.slide-row-left:nth-child(134) {
  animation-delay: 9380ms;
}
.slide-row-left:nth-child(135) {
  animation-delay: 9450ms;
}
.slide-row-left:nth-child(136) {
  animation-delay: 9520ms;
}
.slide-row-left:nth-child(137) {
  animation-delay: 9590ms;
}
.slide-row-left:nth-child(138) {
  animation-delay: 9660ms;
}
.slide-row-left:nth-child(139) {
  animation-delay: 9730ms;
}
.slide-row-left:nth-child(140) {
  animation-delay: 9800ms;
}
.slide-row-left:nth-child(141) {
  animation-delay: 9870ms;
}
.slide-row-left:nth-child(142) {
  animation-delay: 9940ms;
}
.slide-row-left:nth-child(143) {
  animation-delay: 10010ms;
}
.slide-row-left:nth-child(144) {
  animation-delay: 10080ms;
}
.slide-row-left:nth-child(145) {
  animation-delay: 10150ms;
}
.slide-row-left:nth-child(146) {
  animation-delay: 10220ms;
}
.slide-row-left:nth-child(147) {
  animation-delay: 10290ms;
}
.slide-row-left:nth-child(148) {
  animation-delay: 10360ms;
}
.slide-row-left:nth-child(149) {
  animation-delay: 10430ms;
}
.slide-row-left:nth-child(150) {
  animation-delay: 10500ms;
}
.slide-row-left:nth-child(151) {
  animation-delay: 10570ms;
}
.slide-row-left:nth-child(152) {
  animation-delay: 10640ms;
}
.slide-row-left:nth-child(153) {
  animation-delay: 10710ms;
}
.slide-row-left:nth-child(154) {
  animation-delay: 10780ms;
}
.slide-row-left:nth-child(155) {
  animation-delay: 10850ms;
}
.slide-row-left:nth-child(156) {
  animation-delay: 10920ms;
}
.slide-row-left:nth-child(157) {
  animation-delay: 10990ms;
}
.slide-row-left:nth-child(158) {
  animation-delay: 11060ms;
}
.slide-row-left:nth-child(159) {
  animation-delay: 11130ms;
}
.slide-row-left:nth-child(160) {
  animation-delay: 11200ms;
}
.slide-row-left:nth-child(161) {
  animation-delay: 11270ms;
}
.slide-row-left:nth-child(162) {
  animation-delay: 11340ms;
}
.slide-row-left:nth-child(163) {
  animation-delay: 11410ms;
}
.slide-row-left:nth-child(164) {
  animation-delay: 11480ms;
}
.slide-row-left:nth-child(165) {
  animation-delay: 11550ms;
}
.slide-row-left:nth-child(166) {
  animation-delay: 11620ms;
}
.slide-row-left:nth-child(167) {
  animation-delay: 11690ms;
}
.slide-row-left:nth-child(168) {
  animation-delay: 11760ms;
}
.slide-row-left:nth-child(169) {
  animation-delay: 11830ms;
}
.slide-row-left:nth-child(170) {
  animation-delay: 11900ms;
}
.slide-row-left:nth-child(171) {
  animation-delay: 11970ms;
}
.slide-row-left:nth-child(172) {
  animation-delay: 12040ms;
}
.slide-row-left:nth-child(173) {
  animation-delay: 12110ms;
}
.slide-row-left:nth-child(174) {
  animation-delay: 12180ms;
}
.slide-row-left:nth-child(175) {
  animation-delay: 12250ms;
}
.slide-row-left:nth-child(176) {
  animation-delay: 12320ms;
}
.slide-row-left:nth-child(177) {
  animation-delay: 12390ms;
}
.slide-row-left:nth-child(178) {
  animation-delay: 12460ms;
}
.slide-row-left:nth-child(179) {
  animation-delay: 12530ms;
}
.slide-row-left:nth-child(180) {
  animation-delay: 12600ms;
}
.slide-row-left:nth-child(181) {
  animation-delay: 12670ms;
}
.slide-row-left:nth-child(182) {
  animation-delay: 12740ms;
}
.slide-row-left:nth-child(183) {
  animation-delay: 12810ms;
}
.slide-row-left:nth-child(184) {
  animation-delay: 12880ms;
}
.slide-row-left:nth-child(185) {
  animation-delay: 12950ms;
}
.slide-row-left:nth-child(186) {
  animation-delay: 13020ms;
}
.slide-row-left:nth-child(187) {
  animation-delay: 13090ms;
}
.slide-row-left:nth-child(188) {
  animation-delay: 13160ms;
}
.slide-row-left:nth-child(189) {
  animation-delay: 13230ms;
}
.slide-row-left:nth-child(190) {
  animation-delay: 13300ms;
}
.slide-row-left:nth-child(191) {
  animation-delay: 13370ms;
}
.slide-row-left:nth-child(192) {
  animation-delay: 13440ms;
}
.slide-row-left:nth-child(193) {
  animation-delay: 13510ms;
}
.slide-row-left:nth-child(194) {
  animation-delay: 13580ms;
}
.slide-row-left:nth-child(195) {
  animation-delay: 13650ms;
}
.slide-row-left:nth-child(196) {
  animation-delay: 13720ms;
}
.slide-row-left:nth-child(197) {
  animation-delay: 13790ms;
}
.slide-row-left:nth-child(198) {
  animation-delay: 13860ms;
}
.slide-row-left:nth-child(199) {
  animation-delay: 13930ms;
}
.slide-row-left:nth-child(200) {
  animation-delay: 14000ms;
}
.slide-row-left:nth-child(201) {
  animation-delay: 14070ms;
}
.slide-row-left:nth-child(202) {
  animation-delay: 14140ms;
}
.slide-row-left:nth-child(203) {
  animation-delay: 14210ms;
}
.slide-row-left:nth-child(204) {
  animation-delay: 14280ms;
}
.slide-row-left:nth-child(205) {
  animation-delay: 14350ms;
}
.slide-row-left:nth-child(206) {
  animation-delay: 14420ms;
}
.slide-row-left:nth-child(207) {
  animation-delay: 14490ms;
}
.slide-row-left:nth-child(208) {
  animation-delay: 14560ms;
}
.slide-row-left:nth-child(209) {
  animation-delay: 14630ms;
}
.slide-row-left:nth-child(210) {
  animation-delay: 14700ms;
}
.slide-row-left:nth-child(211) {
  animation-delay: 14770ms;
}
.slide-row-left:nth-child(212) {
  animation-delay: 14840ms;
}
.slide-row-left:nth-child(213) {
  animation-delay: 14910ms;
}
.slide-row-left:nth-child(214) {
  animation-delay: 14980ms;
}
.slide-row-left:nth-child(215) {
  animation-delay: 15050ms;
}
.slide-row-left:nth-child(216) {
  animation-delay: 15120ms;
}
.slide-row-left:nth-child(217) {
  animation-delay: 15190ms;
}
.slide-row-left:nth-child(218) {
  animation-delay: 15260ms;
}
.slide-row-left:nth-child(219) {
  animation-delay: 15330ms;
}
.slide-row-left:nth-child(220) {
  animation-delay: 15400ms;
}
.slide-row-left:nth-child(221) {
  animation-delay: 15470ms;
}
.slide-row-left:nth-child(222) {
  animation-delay: 15540ms;
}
.slide-row-left:nth-child(223) {
  animation-delay: 15610ms;
}
.slide-row-left:nth-child(224) {
  animation-delay: 15680ms;
}
.slide-row-left:nth-child(225) {
  animation-delay: 15750ms;
}
.slide-row-left:nth-child(226) {
  animation-delay: 15820ms;
}
.slide-row-left:nth-child(227) {
  animation-delay: 15890ms;
}
.slide-row-left:nth-child(228) {
  animation-delay: 15960ms;
}
.slide-row-left:nth-child(229) {
  animation-delay: 16030ms;
}
.slide-row-left:nth-child(230) {
  animation-delay: 16100ms;
}
.slide-row-left:nth-child(231) {
  animation-delay: 16170ms;
}
.slide-row-left:nth-child(232) {
  animation-delay: 16240ms;
}
.slide-row-left:nth-child(233) {
  animation-delay: 16310ms;
}
.slide-row-left:nth-child(234) {
  animation-delay: 16380ms;
}
.slide-row-left:nth-child(235) {
  animation-delay: 16450ms;
}
.slide-row-left:nth-child(236) {
  animation-delay: 16520ms;
}
.slide-row-left:nth-child(237) {
  animation-delay: 16590ms;
}
.slide-row-left:nth-child(238) {
  animation-delay: 16660ms;
}
.slide-row-left:nth-child(239) {
  animation-delay: 16730ms;
}
.slide-row-left:nth-child(240) {
  animation-delay: 16800ms;
}
.slide-row-left:nth-child(241) {
  animation-delay: 16870ms;
}
.slide-row-left:nth-child(242) {
  animation-delay: 16940ms;
}
.slide-row-left:nth-child(243) {
  animation-delay: 17010ms;
}
.slide-row-left:nth-child(244) {
  animation-delay: 17080ms;
}
.slide-row-left:nth-child(245) {
  animation-delay: 17150ms;
}
.slide-row-left:nth-child(246) {
  animation-delay: 17220ms;
}
.slide-row-left:nth-child(247) {
  animation-delay: 17290ms;
}
.slide-row-left:nth-child(248) {
  animation-delay: 17360ms;
}
.slide-row-left:nth-child(249) {
  animation-delay: 17430ms;
}
.slide-row-left:nth-child(250) {
  animation-delay: 17500ms;
}
.slide-row-left:nth-child(251) {
  animation-delay: 17570ms;
}
.slide-row-left:nth-child(252) {
  animation-delay: 17640ms;
}
.slide-row-left:nth-child(253) {
  animation-delay: 17710ms;
}
.slide-row-left:nth-child(254) {
  animation-delay: 17780ms;
}
.slide-row-left:nth-child(255) {
  animation-delay: 17850ms;
}
.slide-row-left:nth-child(256) {
  animation-delay: 17920ms;
}
.slide-row-left:nth-child(257) {
  animation-delay: 17990ms;
}
.slide-row-left:nth-child(258) {
  animation-delay: 18060ms;
}
.slide-row-left:nth-child(259) {
  animation-delay: 18130ms;
}
.slide-row-left:nth-child(260) {
  animation-delay: 18200ms;
}
.slide-row-left:nth-child(261) {
  animation-delay: 18270ms;
}
.slide-row-left:nth-child(262) {
  animation-delay: 18340ms;
}
.slide-row-left:nth-child(263) {
  animation-delay: 18410ms;
}
.slide-row-left:nth-child(264) {
  animation-delay: 18480ms;
}
.slide-row-left:nth-child(265) {
  animation-delay: 18550ms;
}
.slide-row-left:nth-child(266) {
  animation-delay: 18620ms;
}
.slide-row-left:nth-child(267) {
  animation-delay: 18690ms;
}
.slide-row-left:nth-child(268) {
  animation-delay: 18760ms;
}
.slide-row-left:nth-child(269) {
  animation-delay: 18830ms;
}
.slide-row-left:nth-child(270) {
  animation-delay: 18900ms;
}
.slide-row-left:nth-child(271) {
  animation-delay: 18970ms;
}
.slide-row-left:nth-child(272) {
  animation-delay: 19040ms;
}
.slide-row-left:nth-child(273) {
  animation-delay: 19110ms;
}
.slide-row-left:nth-child(274) {
  animation-delay: 19180ms;
}
.slide-row-left:nth-child(275) {
  animation-delay: 19250ms;
}
.slide-row-left:nth-child(276) {
  animation-delay: 19320ms;
}
.slide-row-left:nth-child(277) {
  animation-delay: 19390ms;
}
.slide-row-left:nth-child(278) {
  animation-delay: 19460ms;
}
.slide-row-left:nth-child(279) {
  animation-delay: 19530ms;
}
.slide-row-left:nth-child(280) {
  animation-delay: 19600ms;
}
.slide-row-left:nth-child(281) {
  animation-delay: 19670ms;
}
.slide-row-left:nth-child(282) {
  animation-delay: 19740ms;
}
.slide-row-left:nth-child(283) {
  animation-delay: 19810ms;
}
.slide-row-left:nth-child(284) {
  animation-delay: 19880ms;
}
.slide-row-left:nth-child(285) {
  animation-delay: 19950ms;
}
.slide-row-left:nth-child(286) {
  animation-delay: 20020ms;
}
.slide-row-left:nth-child(287) {
  animation-delay: 20090ms;
}
.slide-row-left:nth-child(288) {
  animation-delay: 20160ms;
}
.slide-row-left:nth-child(289) {
  animation-delay: 20230ms;
}
.slide-row-left:nth-child(290) {
  animation-delay: 20300ms;
}
.slide-row-left:nth-child(291) {
  animation-delay: 20370ms;
}
.slide-row-left:nth-child(292) {
  animation-delay: 20440ms;
}
.slide-row-left:nth-child(293) {
  animation-delay: 20510ms;
}
.slide-row-left:nth-child(294) {
  animation-delay: 20580ms;
}
.slide-row-left:nth-child(295) {
  animation-delay: 20650ms;
}
.slide-row-left:nth-child(296) {
  animation-delay: 20720ms;
}
.slide-row-left:nth-child(297) {
  animation-delay: 20790ms;
}
.slide-row-left:nth-child(298) {
  animation-delay: 20860ms;
}
.slide-row-left:nth-child(299) {
  animation-delay: 20930ms;
}
.slide-row-left:nth-child(300) {
  animation-delay: 21000ms;
}
.slide-row-left:nth-child(301) {
  animation-delay: 21070ms;
}
.slide-row-left:nth-child(302) {
  animation-delay: 21140ms;
}
.slide-row-left:nth-child(303) {
  animation-delay: 21210ms;
}
.slide-row-left:nth-child(304) {
  animation-delay: 21280ms;
}
.slide-row-left:nth-child(305) {
  animation-delay: 21350ms;
}
.slide-row-left:nth-child(306) {
  animation-delay: 21420ms;
}
.slide-row-left:nth-child(307) {
  animation-delay: 21490ms;
}
.slide-row-left:nth-child(308) {
  animation-delay: 21560ms;
}
.slide-row-left:nth-child(309) {
  animation-delay: 21630ms;
}
.slide-row-left:nth-child(310) {
  animation-delay: 21700ms;
}
.slide-row-left:nth-child(311) {
  animation-delay: 21770ms;
}
.slide-row-left:nth-child(312) {
  animation-delay: 21840ms;
}
.slide-row-left:nth-child(313) {
  animation-delay: 21910ms;
}
.slide-row-left:nth-child(314) {
  animation-delay: 21980ms;
}
.slide-row-left:nth-child(315) {
  animation-delay: 22050ms;
}
.slide-row-left:nth-child(316) {
  animation-delay: 22120ms;
}
.slide-row-left:nth-child(317) {
  animation-delay: 22190ms;
}
.slide-row-left:nth-child(318) {
  animation-delay: 22260ms;
}
.slide-row-left:nth-child(319) {
  animation-delay: 22330ms;
}
.slide-row-left:nth-child(320) {
  animation-delay: 22400ms;
}
.slide-row-left:nth-child(321) {
  animation-delay: 22470ms;
}
.slide-row-left:nth-child(322) {
  animation-delay: 22540ms;
}
.slide-row-left:nth-child(323) {
  animation-delay: 22610ms;
}
.slide-row-left:nth-child(324) {
  animation-delay: 22680ms;
}
.slide-row-left:nth-child(325) {
  animation-delay: 22750ms;
}
.slide-row-left:nth-child(326) {
  animation-delay: 22820ms;
}
.slide-row-left:nth-child(327) {
  animation-delay: 22890ms;
}
.slide-row-left:nth-child(328) {
  animation-delay: 22960ms;
}
.slide-row-left:nth-child(329) {
  animation-delay: 23030ms;
}
.slide-row-left:nth-child(330) {
  animation-delay: 23100ms;
}
.slide-row-left:nth-child(331) {
  animation-delay: 23170ms;
}
.slide-row-left:nth-child(332) {
  animation-delay: 23240ms;
}
.slide-row-left:nth-child(333) {
  animation-delay: 23310ms;
}
.slide-row-left:nth-child(334) {
  animation-delay: 23380ms;
}
.slide-row-left:nth-child(335) {
  animation-delay: 23450ms;
}
.slide-row-left:nth-child(336) {
  animation-delay: 23520ms;
}
.slide-row-left:nth-child(337) {
  animation-delay: 23590ms;
}
.slide-row-left:nth-child(338) {
  animation-delay: 23660ms;
}
.slide-row-left:nth-child(339) {
  animation-delay: 23730ms;
}
.slide-row-left:nth-child(340) {
  animation-delay: 23800ms;
}
.slide-row-left:nth-child(341) {
  animation-delay: 23870ms;
}
.slide-row-left:nth-child(342) {
  animation-delay: 23940ms;
}
.slide-row-left:nth-child(343) {
  animation-delay: 24010ms;
}
.slide-row-left:nth-child(344) {
  animation-delay: 24080ms;
}
.slide-row-left:nth-child(345) {
  animation-delay: 24150ms;
}
.slide-row-left:nth-child(346) {
  animation-delay: 24220ms;
}
.slide-row-left:nth-child(347) {
  animation-delay: 24290ms;
}
.slide-row-left:nth-child(348) {
  animation-delay: 24360ms;
}
.slide-row-left:nth-child(349) {
  animation-delay: 24430ms;
}
.slide-row-left:nth-child(350) {
  animation-delay: 24500ms;
}
.slide-row-left:nth-child(351) {
  animation-delay: 24570ms;
}
.slide-row-left:nth-child(352) {
  animation-delay: 24640ms;
}
.slide-row-left:nth-child(353) {
  animation-delay: 24710ms;
}
.slide-row-left:nth-child(354) {
  animation-delay: 24780ms;
}
.slide-row-left:nth-child(355) {
  animation-delay: 24850ms;
}
.slide-row-left:nth-child(356) {
  animation-delay: 24920ms;
}
.slide-row-left:nth-child(357) {
  animation-delay: 24990ms;
}
.slide-row-left:nth-child(358) {
  animation-delay: 25060ms;
}
.slide-row-left:nth-child(359) {
  animation-delay: 25130ms;
}
.slide-row-left:nth-child(360) {
  animation-delay: 25200ms;
}
.slide-row-left:nth-child(361) {
  animation-delay: 25270ms;
}
.slide-row-left:nth-child(362) {
  animation-delay: 25340ms;
}
.slide-row-left:nth-child(363) {
  animation-delay: 25410ms;
}
.slide-row-left:nth-child(364) {
  animation-delay: 25480ms;
}
.slide-row-left:nth-child(365) {
  animation-delay: 25550ms;
}
.slide-row-left:nth-child(366) {
  animation-delay: 25620ms;
}
.slide-row-left:nth-child(367) {
  animation-delay: 25690ms;
}
.slide-row-left:nth-child(368) {
  animation-delay: 25760ms;
}
.slide-row-left:nth-child(369) {
  animation-delay: 25830ms;
}
.slide-row-left:nth-child(370) {
  animation-delay: 25900ms;
}
.slide-row-left:nth-child(371) {
  animation-delay: 25970ms;
}
.slide-row-left:nth-child(372) {
  animation-delay: 26040ms;
}
.slide-row-left:nth-child(373) {
  animation-delay: 26110ms;
}
.slide-row-left:nth-child(374) {
  animation-delay: 26180ms;
}
.slide-row-left:nth-child(375) {
  animation-delay: 26250ms;
}
.slide-row-left:nth-child(376) {
  animation-delay: 26320ms;
}
.slide-row-left:nth-child(377) {
  animation-delay: 26390ms;
}
.slide-row-left:nth-child(378) {
  animation-delay: 26460ms;
}
.slide-row-left:nth-child(379) {
  animation-delay: 26530ms;
}
.slide-row-left:nth-child(380) {
  animation-delay: 26600ms;
}
.slide-row-left:nth-child(381) {
  animation-delay: 26670ms;
}
.slide-row-left:nth-child(382) {
  animation-delay: 26740ms;
}
.slide-row-left:nth-child(383) {
  animation-delay: 26810ms;
}
.slide-row-left:nth-child(384) {
  animation-delay: 26880ms;
}
.slide-row-left:nth-child(385) {
  animation-delay: 26950ms;
}
.slide-row-left:nth-child(386) {
  animation-delay: 27020ms;
}
.slide-row-left:nth-child(387) {
  animation-delay: 27090ms;
}
.slide-row-left:nth-child(388) {
  animation-delay: 27160ms;
}
.slide-row-left:nth-child(389) {
  animation-delay: 27230ms;
}
.slide-row-left:nth-child(390) {
  animation-delay: 27300ms;
}
.slide-row-left:nth-child(391) {
  animation-delay: 27370ms;
}
.slide-row-left:nth-child(392) {
  animation-delay: 27440ms;
}
.slide-row-left:nth-child(393) {
  animation-delay: 27510ms;
}
.slide-row-left:nth-child(394) {
  animation-delay: 27580ms;
}
.slide-row-left:nth-child(395) {
  animation-delay: 27650ms;
}
.slide-row-left:nth-child(396) {
  animation-delay: 27720ms;
}
.slide-row-left:nth-child(397) {
  animation-delay: 27790ms;
}
.slide-row-left:nth-child(398) {
  animation-delay: 27860ms;
}
.slide-row-left:nth-child(399) {
  animation-delay: 27930ms;
}
.slide-row-left:nth-child(400) {
  animation-delay: 28000ms;
}
.slide-row-left:nth-child(401) {
  animation-delay: 28070ms;
}
.slide-row-left:nth-child(402) {
  animation-delay: 28140ms;
}
.slide-row-left:nth-child(403) {
  animation-delay: 28210ms;
}
.slide-row-left:nth-child(404) {
  animation-delay: 28280ms;
}
.slide-row-left:nth-child(405) {
  animation-delay: 28350ms;
}
.slide-row-left:nth-child(406) {
  animation-delay: 28420ms;
}
.slide-row-left:nth-child(407) {
  animation-delay: 28490ms;
}
.slide-row-left:nth-child(408) {
  animation-delay: 28560ms;
}
.slide-row-left:nth-child(409) {
  animation-delay: 28630ms;
}
.slide-row-left:nth-child(410) {
  animation-delay: 28700ms;
}
.slide-row-left:nth-child(411) {
  animation-delay: 28770ms;
}
.slide-row-left:nth-child(412) {
  animation-delay: 28840ms;
}
.slide-row-left:nth-child(413) {
  animation-delay: 28910ms;
}
.slide-row-left:nth-child(414) {
  animation-delay: 28980ms;
}
.slide-row-left:nth-child(415) {
  animation-delay: 29050ms;
}
.slide-row-left:nth-child(416) {
  animation-delay: 29120ms;
}
.slide-row-left:nth-child(417) {
  animation-delay: 29190ms;
}
.slide-row-left:nth-child(418) {
  animation-delay: 29260ms;
}
.slide-row-left:nth-child(419) {
  animation-delay: 29330ms;
}
.slide-row-left:nth-child(420) {
  animation-delay: 29400ms;
}
.slide-row-left:nth-child(421) {
  animation-delay: 29470ms;
}
.slide-row-left:nth-child(422) {
  animation-delay: 29540ms;
}
.slide-row-left:nth-child(423) {
  animation-delay: 29610ms;
}
.slide-row-left:nth-child(424) {
  animation-delay: 29680ms;
}
.slide-row-left:nth-child(425) {
  animation-delay: 29750ms;
}
.slide-row-left:nth-child(426) {
  animation-delay: 29820ms;
}
.slide-row-left:nth-child(427) {
  animation-delay: 29890ms;
}
.slide-row-left:nth-child(428) {
  animation-delay: 29960ms;
}
.slide-row-left:nth-child(429) {
  animation-delay: 30030ms;
}
.slide-row-left:nth-child(430) {
  animation-delay: 30100ms;
}
.slide-row-left:nth-child(431) {
  animation-delay: 30170ms;
}
.slide-row-left:nth-child(432) {
  animation-delay: 30240ms;
}
.slide-row-left:nth-child(433) {
  animation-delay: 30310ms;
}
.slide-row-left:nth-child(434) {
  animation-delay: 30380ms;
}
.slide-row-left:nth-child(435) {
  animation-delay: 30450ms;
}
.slide-row-left:nth-child(436) {
  animation-delay: 30520ms;
}
.slide-row-left:nth-child(437) {
  animation-delay: 30590ms;
}
.slide-row-left:nth-child(438) {
  animation-delay: 30660ms;
}
.slide-row-left:nth-child(439) {
  animation-delay: 30730ms;
}
.slide-row-left:nth-child(440) {
  animation-delay: 30800ms;
}
.slide-row-left:nth-child(441) {
  animation-delay: 30870ms;
}
.slide-row-left:nth-child(442) {
  animation-delay: 30940ms;
}
.slide-row-left:nth-child(443) {
  animation-delay: 31010ms;
}
.slide-row-left:nth-child(444) {
  animation-delay: 31080ms;
}
.slide-row-left:nth-child(445) {
  animation-delay: 31150ms;
}
.slide-row-left:nth-child(446) {
  animation-delay: 31220ms;
}
.slide-row-left:nth-child(447) {
  animation-delay: 31290ms;
}
.slide-row-left:nth-child(448) {
  animation-delay: 31360ms;
}
.slide-row-left:nth-child(449) {
  animation-delay: 31430ms;
}
.slide-row-left:nth-child(450) {
  animation-delay: 31500ms;
}
.slide-row-left:nth-child(451) {
  animation-delay: 31570ms;
}
.slide-row-left:nth-child(452) {
  animation-delay: 31640ms;
}
.slide-row-left:nth-child(453) {
  animation-delay: 31710ms;
}
.slide-row-left:nth-child(454) {
  animation-delay: 31780ms;
}
.slide-row-left:nth-child(455) {
  animation-delay: 31850ms;
}
.slide-row-left:nth-child(456) {
  animation-delay: 31920ms;
}
.slide-row-left:nth-child(457) {
  animation-delay: 31990ms;
}
.slide-row-left:nth-child(458) {
  animation-delay: 32060ms;
}
.slide-row-left:nth-child(459) {
  animation-delay: 32130ms;
}
.slide-row-left:nth-child(460) {
  animation-delay: 32200ms;
}
.slide-row-left:nth-child(461) {
  animation-delay: 32270ms;
}
.slide-row-left:nth-child(462) {
  animation-delay: 32340ms;
}
.slide-row-left:nth-child(463) {
  animation-delay: 32410ms;
}
.slide-row-left:nth-child(464) {
  animation-delay: 32480ms;
}
.slide-row-left:nth-child(465) {
  animation-delay: 32550ms;
}
.slide-row-left:nth-child(466) {
  animation-delay: 32620ms;
}
.slide-row-left:nth-child(467) {
  animation-delay: 32690ms;
}
.slide-row-left:nth-child(468) {
  animation-delay: 32760ms;
}
.slide-row-left:nth-child(469) {
  animation-delay: 32830ms;
}
.slide-row-left:nth-child(470) {
  animation-delay: 32900ms;
}
.slide-row-left:nth-child(471) {
  animation-delay: 32970ms;
}
.slide-row-left:nth-child(472) {
  animation-delay: 33040ms;
}
.slide-row-left:nth-child(473) {
  animation-delay: 33110ms;
}
.slide-row-left:nth-child(474) {
  animation-delay: 33180ms;
}
.slide-row-left:nth-child(475) {
  animation-delay: 33250ms;
}
.slide-row-left:nth-child(476) {
  animation-delay: 33320ms;
}
.slide-row-left:nth-child(477) {
  animation-delay: 33390ms;
}
.slide-row-left:nth-child(478) {
  animation-delay: 33460ms;
}
.slide-row-left:nth-child(479) {
  animation-delay: 33530ms;
}
.slide-row-left:nth-child(480) {
  animation-delay: 33600ms;
}
.slide-row-left:nth-child(481) {
  animation-delay: 33670ms;
}
.slide-row-left:nth-child(482) {
  animation-delay: 33740ms;
}
.slide-row-left:nth-child(483) {
  animation-delay: 33810ms;
}
.slide-row-left:nth-child(484) {
  animation-delay: 33880ms;
}
.slide-row-left:nth-child(485) {
  animation-delay: 33950ms;
}
.slide-row-left:nth-child(486) {
  animation-delay: 34020ms;
}
.slide-row-left:nth-child(487) {
  animation-delay: 34090ms;
}
.slide-row-left:nth-child(488) {
  animation-delay: 34160ms;
}
.slide-row-left:nth-child(489) {
  animation-delay: 34230ms;
}
.slide-row-left:nth-child(490) {
  animation-delay: 34300ms;
}
.slide-row-left:nth-child(491) {
  animation-delay: 34370ms;
}
.slide-row-left:nth-child(492) {
  animation-delay: 34440ms;
}
.slide-row-left:nth-child(493) {
  animation-delay: 34510ms;
}
.slide-row-left:nth-child(494) {
  animation-delay: 34580ms;
}
.slide-row-left:nth-child(495) {
  animation-delay: 34650ms;
}
.slide-row-left:nth-child(496) {
  animation-delay: 34720ms;
}
.slide-row-left:nth-child(497) {
  animation-delay: 34790ms;
}
.slide-row-left:nth-child(498) {
  animation-delay: 34860ms;
}
.slide-row-left:nth-child(499) {
  animation-delay: 34930ms;
}
.slide-row-left:nth-child(500) {
  animation-delay: 35000ms;
}

@keyframes slide-row-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/* text animation for customers with no transactions */
.text-focus-in {
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes text-focus-in {
  0% {
    filter: blur(1px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    height: 50vh;
  }
}
.conditionText {
  font-size: 0.688rem;
  pointer-events: none;
}

.rotateArrow-90 {
  transform: rotate(90deg);
  transition: transform 0.25s ease !important;
}

.rotateArrow-0 {
  transform: rotate(0deg);
  transition: transform 0.25s ease !important;
}

.noPaddingNoBorderTable,
.noPaddingNoBorderTable td {
  background-color: #f2f2f2;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none !important;
}

.paddingNoBorderTable,
.paddingNoBorderTable td {
  background-color: #f2f2f2;
  padding-top: 0px !important;
  padding-bottom: 1rem !important;
  border: none !important;
}

.card-line-btn {
  cursor: pointer !important;
  background-color: white !important;
}

.card-line-btn:hover {
  background-color: #e2dcdc !important;
}

.news-Img {
  border-radius: 20px;
}

.font-description .h2 {
  font-size: 1.52rem !important;
}

.font-description .h3 {
  font-size: 1.389rem !important;
}

#newsBody a {
  color: #ff5200;
}

#newsBody a:hover {
  color: #d14300;
}

.grid-gutter-y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.grid-gutter-x {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media (max-width: 768px) {
  .no-padding-mobile {
    padding: 0px !important;
  }
}
.date-dropdown {
  appearance: none;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: #fff;
  padding: 0.7rem 0.5rem !important;
  color: #4c4c4c !important;
  font-weight: bold;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.177' height='8.248' viewBox='0 0 14.177 8.248'%3E%3Cpath id='angle-left_zurück' d='M.2,6.618,6.718.2A.665.665,0,0,1,7.66.2l.393.393a.665.665,0,0,1,0,.941L2.388,7.089l5.659,5.559a.665.665,0,0,1,0,.941l-.393.393a.665.665,0,0,1-.941,0L.19,7.559A.67.67,0,0,1,.2,6.618Z' transform='translate(0 8.248) rotate(-90)' fill='%23E85F0A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: top 50% right 10%;
  transition: background-image 2s ease !important;
}

.date-dropdown:hover,
.date-dropdown:focus {
  outline: none !important;
}

.custom-spinner {
  position: absolute;
  top: 50%;
  margin-top: -31px;
}

.modal-position {
  justify-content: center;
}

.onboarding-modal {
  background: linear-gradient(to bottom, #ffffff, #fbba00);
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.onboarding-modal-container {
  height: 100%;
  padding: 0px;
  width: 600px;
  position: relative;
  border-radius: 20px;
}

.onboarding-background-image {
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  transition: all 400ms ease-in-out;
}

.onboarding-background-badge {
  width: 690px;
  height: 690px;
  border-radius: 296.7px !important;
  position: absolute;
  top: 240px;
  transform: rotate(20deg);
}

.onboarding-modal-icon {
  z-index: 1;
  position: absolute;
  top: 210px;
  left: 130px;
}

.onboarding-modal-info-container {
  z-index: 1;
  position: relative;
}

.onboarding-modal-body-text {
  font-size: 0.938rem;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  min-height: 100px;
}

.onboarding-modal-body-text p {
  margin-bottom: 2px;
}

.onboarding-modal-scroll-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.onboarding-modal-scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-width: 768px) and (min-width: 366px) {
  .onboarding-modal-container {
    width: 330px;
  }
  .onboarding-background-badge {
    top: 250px;
  }
  .onboarding-modal-icon {
    z-index: 1;
    position: absolute;
    top: 200px;
    left: 90px;
  }
  .onboarding-modal-body-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .onboarding-modal-scroll-container {
    width: 100%;
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media (max-width: 365px) {
  .modal-position {
    margin-top: 0;
  }
  .onboarding-modal-container {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
  .onboarding-modal-body-text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.dropDownContainer {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.dropDownIconSpace {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropDownText {
  width: 100%;
  padding-left: 0.1rem;
  text-align: start;
  display: flex;
  align-items: center;
  font-size: 0.813rem;
}

.dropDownBadge {
  width: 1rem;
  height: 1rem;
  border-radius: 0.43rem !important;
  border: 0.063rem solid #707070;
}

.dropDownBadgeIcon {
  font-size: 0.813rem !important;
}

.filterTile {
  margin-left: 3px;
  margin-right: 3px;
  padding: 0px;
}

.filterTileText {
  color: #ff5200;
  font-size: 0.938rem;
}

.modalFilterTile {
  margin: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 300px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: fixed;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.mt-n10,
.my-n10 {
  margin-top: -6rem !important;
}

@media (min-width: 0) {
  .w-xs-75 {
    width: 75% !important;
  }
}
@media (min-width: 576px) {
  .w-sm-75 {
    width: 75% !important;
  }
}
@media (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
}
@media (min-width: 1024px) {
  .w-lg-75 {
    width: 75% !important;
  }
}
@media (min-width: 1440px) {
  .w-xl-75 {
    width: 75% !important;
  }
}
.bg-yellow {
  background-color: #fbba00;
}

.bg-orange {
  background-color: #ff5200;
}

.bg-white {
  background-color: #ffffff;
}

.bg-lightgray {
  background-color: #f2f2f2;
}

.bg-transparentGray {
  background-color: #f2f2f2;
}

.bg-plumPie {
  background-color: #741635;
}

.bg-green {
  background-color: #65bb25;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "unitText-Regular", "Arial Rounded MT", "Arial", "sans-serif";
}

body {
  background-color: #fbba00 !important;
  height: 100%;
  hyphens: auto;
}

a:hover {
  transition-duration: 250ms !important;
}

.embedded-html-body a {
  color: #ff5200;
}
.embedded-html-body a:hover {
  color: #d14300;
  text-decoration: underline;
  transition-duration: 250ms !important;
}

.tile-dashboard,
.tile,
.greetingTile {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  height: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.tile {
  padding-bottom: 20px;
}

.content-in-tile {
  min-height: calc(100vh - 320px);
}

.greetingTile {
  background-color: transparent !important;
  box-shadow: none !important;
}

.newsTile {
  background-color: #ffffff;
  border-radius: 10px !important;
  height: 100%;
  min-height: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 0px;
}

@media (min-width: 0) {
  .firstRowTile {
    height: 12.5rem !important;
  }
}
@media (min-width: 768px) {
  .firstRowTile {
    height: 15.625rem !important;
  }
}
@media (min-width: 1024px) {
  .firstRowTile {
    height: 12.5rem !important;
  }
}
@media (min-width: 1440px) {
  .firstRowTile {
    height: 15.625rem !important;
  }
}
@media (min-width: 1800px) {
  .firstRowTile {
    height: 16.25rem !important;
  }
}
.link-white-underlined {
  color: #ffffff;
  text-decoration: underline;
}
.link-white-underlined:hover {
  color: #ffffff !important;
  text-decoration: none;
}

.link-orange-underlined {
  color: #ff5200;
  text-decoration: underline;
}
.link-orange-underlined:hover {
  color: #d14300 !important;
}

.inline-link {
  background: none;
  border: none;
  color: #e29d73;
  cursor: pointer;
  transition: all 250ms ease-in-out !important;
}

.inline-link:hover,
.inline-link:focus {
  text-decoration: none;
  color: #ff5200 !important;
  transition-duration: 250ms;
}

.icon-accent-wrapper {
  cursor: pointer;
  background: none !important;
  border: none !important;
  transition: all 250ms ease-in-out !important;
}

.icon-accent-orange {
  color: #ff5200 !important;
  stroke: #ff5200 !important;
  fill: #ff5200 !important;
}
.icon-accent-orange:hover {
  color: #d14300 !important;
  stroke: #d14300 !important;
  fill: #d14300 !important;
  transition-duration: 250ms;
}

.icon-accent-lightGray {
  color: #f2f2f2 !important;
  stroke: #f2f2f2 !important;
  fill: #f2f2f2 !important;
  cursor: default !important;
}

.icon-accent-white {
  color: #ffffff !important;
  stroke: #ffffff !important;
}

.icon-accent-plumPie {
  color: #741635 !important;
  stroke: #741635 !important;
}

.rotateArrow-90 {
  transform: rotate(90deg);
  transition: transform 0.25s ease !important;
}

.rotateArrow-0 {
  transform: rotate(0deg);
  transition: transform 0.25s ease !important;
}

@media (max-width: 768px) {
  .tile {
    background-color: #ffffff;
    border-radius: 0;
    padding-top: 0px;
    padding-bottom: 30px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    box-shadow: none !important;
    height: 100%;
  }
  .greetingTile {
    background-color: #ffffff;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    box-shadow: none !important;
    height: 100%;
  }
  .content-in-tile {
    min-height: calc(100vh - 280px);
    max-height: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .container-fluid-mobile {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
    max-width: 100% !important;
  }
}
.row-eq {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-wrap: wrap !important;
  height: 100%;
}

.row-eq > [class*=col-] {
  display: flex !important;
  flex-direction: column !important;
}

.cursor-pointer {
  cursor: pointer;
}

.category-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px !important;
  padding: 0 !important;
}

.category-breadcrumb-item {
  padding-right: 0.5rem !important;
  font-family: "unitText-regular";
  font-size: 0.844rem;
  color: #979797;
}
.category-breadcrumb-item::before {
  float: left;
  padding-right: 0.3rem;
  color: #979797;
  content: "<";
}
.category-breadcrumb-item:hover,
.category-breadcrumb-item :focus {
  text-decoration: underline;
  transition-duration: 250ms;
}
.category-breadcrumb-item.active {
  font-family: "unitText-bold";
}

.text-orange {
  color: #ff5200;
}

.link-no-hover:hover {
  color: inherit;
  text-decoration: none;
}

.svg-info-icon-base {
  fill: transparent;
}

.svg-info-icon:hover .svg-info-icon-base {
  fill: #ff5200;
}

.svg-info-icon:hover .svg-info-icon-stroke {
  fill: #ffffff;
  stroke: #ffffff;
}

.custom-data-carousel-arrow-right {
  margin-left: -12px;
  z-index: 2;
}

.custom-data-carousel-arrow-left {
  margin-right: -12px;
  z-index: 2;
}

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e50000;
  --orange: #ff5200;
  --yellow: #fbba00;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #fbba00;
  --danger: #e50000;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1440px;
  --breakpoint-xxl: 1800px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 990px;
  }
}
@media (min-width: 1440px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
@media (min-width: 1800px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1540px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1800px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feecb8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fddb7a;
}

.table-hover .table-warning:hover {
  background-color: #fee59f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fee59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8b8b8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f17a7a;
}

.table-hover .table-danger:hover {
  background-color: #f6a1a1;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6a1a1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1799.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e50000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(229, 0, 0, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e50000;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e50000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e50000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e50000;
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e50000;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e50000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e50000' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e50000;
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e50000;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e50000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e50000;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff1919;
  background-color: #ff1919;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e50000;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e50000;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e50000;
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fbba00;
  border-color: #fbba00;
}
.btn-warning:hover {
  color: #212529;
  background-color: #d59e00;
  border-color: #c89400;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #d59e00;
  border-color: #c89400;
  box-shadow: 0 0 0 0.2rem rgba(218, 164, 6, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #fbba00;
  border-color: #fbba00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #c89400;
  border-color: #bb8b00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 164, 6, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #e50000;
  border-color: #e50000;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #bf0000;
  border-color: #b20000;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #bf0000;
  border-color: #b20000;
  box-shadow: 0 0 0 0.2rem rgba(233, 38, 38, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #e50000;
  border-color: #e50000;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #b20000;
  border-color: #a50000;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 38, 38, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #fbba00;
  border-color: #fbba00;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #fbba00;
  border-color: #fbba00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 186, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbba00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fbba00;
  border-color: #fbba00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 186, 0, 0.5);
}

.btn-outline-danger {
  color: #e50000;
  border-color: #e50000;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #e50000;
  border-color: #e50000;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e50000;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #e50000;
  border-color: #e50000;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1800px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1799.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fbba00;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #c89400;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 186, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #e50000;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #b20000;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 0, 0, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #836100;
  background-color: #fef1cc;
  border-color: #feecb8;
}
.alert-warning hr {
  border-top-color: #fee59f;
}
.alert-warning .alert-link {
  color: #503b00;
}

.alert-danger {
  color: #770000;
  background-color: #facccc;
  border-color: #f8b8b8;
}
.alert-danger hr {
  border-top-color: #f6a1a1;
}
.alert-danger .alert-link {
  color: #440000;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1800px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #836100;
  background-color: #feecb8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #836100;
  background-color: #fee59f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #836100;
  border-color: #836100;
}

.list-group-item-danger {
  color: #770000;
  background-color: #f8b8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #770000;
  background-color: #f6a1a1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #770000;
  border-color: #770000;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #fbba00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c89400 !important;
}

.bg-danger {
  background-color: #e50000 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b20000 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #fbba00 !important;
}

.border-danger {
  border-color: #e50000 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1800px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1800px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1800px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1800px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1800px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #fbba00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #af8100 !important;
}

.text-danger {
  color: #e50000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #990000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1024px !important;
  }
  .container {
    min-width: 1024px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
// this code is used to place the footer on the bottom of the page
html, body, #root{
        height: 100%;
}
#root{
        display: flex;
        flex-direction: column;
}
.footer{
        margin-top: auto;
}
*/
@media (min-width: 1024px) {
  .custom-data-carousel-slider-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

/*# sourceMappingURL=main.css.map */
